import axios from "axios";
import React, { useState } from "react";
import db, { extension } from "mime-db";
import { saveAs } from "file-saver";

const useDownloader = () => {
	const [progress, setProgress] = useState(0);
	const [error, setError] = useState(null);
	const [complete, setComplete] = useState(false);
	const [isInProgress, setIsInProgress] = useState(false);

	const downloadFile = async (url, filename = "") => {
		try {
            setComplete(false);
            setIsInProgress(true);
			const response = await axios.get(url, {
				responseType: "blob",
				onDownloadProgress: (progressEvent) => {
					if (progressEvent.total && progressEvent.loaded) {
						const percentCompleted = Math.round(
							(progressEvent.loaded * 100) / progressEvent.total
						);
						setProgress(percentCompleted);
						if (percentCompleted === 100) {
							setComplete(true);
							setIsInProgress(false);
						}
					} else {
						setComplete(true);
						setIsInProgress(false);
					}
				},
			});

			const blob = new Blob([response.data], {
				type: extension[url.split(".").pop()],
			});
			saveAs(blob, filename || url.split("/").pop());
		} catch (error) {
			console.error("Error downloading file:", error);
			setIsInProgress(false);
			setError(error);
		}
	};

	return { download: downloadFile, progress, error, complete, isInProgress };
};

export default useDownloader;
