import {
	Block,
	Button,
	Input,
	Link,
	List,
	ListItem,
	NavLeft,
	NavTitle,
	Navbar,
	Tab,
	Toggle,
	f7,
	Popup,
	Page,
	Navbar as PopupNavbar,
	NavRight,
} from "framework7-react";
import React, { useMemo, useState } from "react";
import LazyLoaderWraper from "../../../../components/misc/lazyloaderWraper";
import MaterialIcon from "../../../../components/misc/materialIcon";
import { useDispatch, useSelector } from "react-redux";
import { createGroupService } from "../../../../redux/features/chatSlice/createGroup";
import { useUpdateEffect, useWindowSize } from "react-use";
import { STATUS_CODE } from "../../../../constants";
import { Add } from "iconsax-react";
import { useTranslation } from "react-i18next";
import "./style.css";
import { createOpenDialog, getFileType } from "../../../../utils/functions";
import { uploadFileService } from "../../../../redux/features/chatSlice/uploadFile";
import userimage from "../../../../assets/images/placeholders/user.png";
import groupimage from "../../../../assets/images/placeholders/group.png";
import imgcamera from "../../../../assets/images/icons/group-image.svg";

const NewGroupChat = ({
	f7router,
	selectedItems,
	setSelectedItems,
	newGroupData,
	setNewGroupData,
}) => {
	const { width } = useWindowSize();
	const isMobile = useMemo(() => width < 775, [width]);

	const dispatch = useDispatch();
	const { t, i18n } = useTranslation();
	const [selectedImage, setSelectedImage] = useState();
	const [popupOpen, setPopupOpen] = useState(false);
	const [adminPopupOpen, setAdminPopupOpen] = useState(false);
	// const [imgUrl, setImgUrl] = useState();

	const { loginResponse } = useSelector((state) => state.loginSlice);
	const { uploadFileResponse } = useSelector((state) => state.uploadFileSlice);
	const { createGroupResponse, isLoading } = useSelector(
		(state) => state.createGroupSlice
	);

	const groupUsers = selectedItems?.map((user) => user?.id);
	newGroupData.users = groupUsers;

	const onInputChange = (key, value) => {
		setNewGroupData({ ...newGroupData, [key]: value });
	};

	useUpdateEffect(() => {
		if (uploadFileResponse?.code === STATUS_CODE.SUCCESS) {
			const uploadedFile = uploadFileResponse.data[0].file_url;
			setNewGroupData({ ...newGroupData, group_image: uploadedFile });
		}
	}, [uploadFileResponse]);

	const handleAdminToggle = (userId) => {
		const updatedAdmins = newGroupData.admins.includes(userId)
			? newGroupData.admins.filter((id) => id !== userId)
			: [...newGroupData.admins, userId];

		setNewGroupData({ ...newGroupData, admins: updatedAdmins });
	};

	const attachFileHandler = () => {
		const fileInput = createOpenDialog();
		fileInput.onchange = (_) => {
			const file = fileInput.files[0];
			const url = URL.createObjectURL(file);
			setSelectedImage(url);
			uploadImage(file);
			fileInput.remove();
		};

		fileInput.click();
	};
	const uploadImage = async (file) => {
		try {
			const formData = new FormData();
			formData.append("type", getFileType(file));
			formData.append("file", file);
			dispatch(uploadFileService(formData));
		} catch (error) {
			console.error("Error uploading image:", error);
		}
	};

	useUpdateEffect(() => {
		if (!isLoading && createGroupResponse?.code === STATUS_CODE.SUCCESS) {
			setNewGroupData({
				who_can_edit: "all",
				change_group_forwarding: 1,
				users: [],
				who_can_send_message: "all",
				owner_id: null,
				calling_feature: 0,
				admins: [],
				group_name: "",
				description: "",
				approve_new_members: 0,
				change_main_group_sharing: 1,
				group_image: "",
			});
			if (isMobile && f7.router.currentRoute.path !== "/chats/null/single")
				f7.views.main.router.navigate(`/chats/null/single`);
			f7.store.dispatch(
				"setActiveConversation",
				parseInt(createGroupResponse?.data?.chat_id)
			);
			f7.store.dispatch("setActiveConversationType", "group");
			f7.panel.close("#newChatPanel", true);
		}
	}, [createGroupResponse]);

	const onCreateGroup = () => {
		
		dispatch(createGroupService(newGroupData));
		// db.chats
		// 	.where({ chat_id: chat_id })
		// 	.modify({
		// 		nickname: groupName,
		// 		group_image: imgUrl,
		// 	})
		// 	.then(() => {
		// 		console.log("Chat updated successfully.");
		// 	})
		// 	.catch((error) => {
		// 		console.error("Error updating chat:", error);
		// 	});
		// switchMode();
	};
	return (
		<Tab id="group-chat-tab" className="h-full">
			<Navbar>
				<NavLeft>
					<Link tabLink="#add-participants">
						<MaterialIcon
							icon="arrow_back"
							fill
							color="--f7-chat-heading-color"
						/>
					</Link>
				</NavLeft>
				<NavTitle className="text-[--f7-heading-color] font-bold">
					{t("New Group Chat")}
				</NavTitle>
			</Navbar>

			<div className="page-content pt-0 h-[calc(100vh-100px)] overflow-scroll">
				<Block className="flex gap-[10px] flex-wrap bg-transprent">
					<label
						htmlFor="image-input"
						className="relative flex w-[62px] h-[62px] items-center justify-center bg-[--f7-navbar-bg-color] rounded-full cursor-pointer"
						style={{
							backgroundImage: `url(${groupimage})`,
							backgroundSize: "cover",
							backgroundPosition: "center",
						}}>
						{selectedImage && (
							<LazyLoaderWraper
								src={selectedImage}
								height={62}
								width={62}
								alt=""
								className="rounded-full align-bottom"
								wrapperclassname="rounded-full align-bottom"
							/>
						)}
						<div className="created_group absolute inset-0 flex justify-center items-center">
							<img
								src={imgcamera}
								onClick={attachFileHandler}
								className="cursor-pointer"
							/>
						</div>
					</label>

					<div className="flex-1 group-custom-input input">
						<input
							type="text"
							placeholder={t("Type group name here")}
							className="py-[10px] pl-[52px] pr-[20px]"
							value={newGroupData.group_name}
							onChange={(e) => onInputChange("group_name", e.target.value)}
						/>
						<Button className="absolute top-0 rounded-full">
							<MaterialIcon
								size={48}
								icon="sentiment_satisfied"
								weight={700}
								className="text-[22px] color-secondary"
							/>
						</Button>
					</div>
				</Block>
				<div className=" px-5">
					<p className="text-[14px] font-bold text-[--f7-chat-heading-color]">
						{" "}
						{t("GROUP DESCRIPTION")}
					</p>
					<Input
						value={newGroupData.description}
						onChange={(e) => onInputChange("description", e.target.value)}
						placeholder={t("Type group description here")}
						type="text"
						className="w-full mt-[5px] group-custom-input "></Input>
					<div className="flex justify-between mt-[40px] ">
						<p className="text-[14px] font-bold text-[--f7-chat-heading-color]">
							{t("GROUP MEMBERS")} {selectedItems?.length}
						</p>
						<p className="text-[14px] font-bold flex gap-1 items-center cursor-pointer text-[--f7-md-primary]">
							<Link tabLink="#add-participants">
								<Add color="#0e99fe" /> {t("ADD_MORE")}
							</Link>
						</p>
					</div>
					<div className="flex gap-[7px] mt-[20px] flex-wrap">
						{selectedItems?.map((member, index) =>
							index > 8 ? null : (
								<div className="w-[55px] mb-2" key={member.id}>
									<LazyLoaderWraper
										className="w-[55px] h-[55px] rounded-full"
										src={member.profile_image}
										placeholder={member.image}
									/>
									<p
										className="w-[50px]
                                text-[14px] font-[500] text-[--f7-heading-color]
                                overflow-hidden whitespace-nowrap text-ellipsis text-center">
										{member?.name}
									</p>
								</div>
							)
						)}
						{selectedItems?.length > 9 && (
							<div
								className="w-[55px] mb-2 cursor-pointer"
								onClick={() => setPopupOpen(true)}>
								<div className="w-[55px] h-[55px] rounded-full flex items-center justify-center bg-[#c6c6c6]">
									<MaterialIcon
										icon="more_horiz"
										size={20}
										weight={700}
										grad={200}
										color="white"
									/>
								</div>
								<p
									className="w-[50px]
                                text-[14px] font-[500] text-[--f7-heading-color]
                                overflow-hidden whitespace-nowrap text-ellipsis text-center">
									More
								</p>
							</div>
						)}
					</div>
					<p
						className="text-[14px] font-bold text-center mt-2 cursor-pointer text-[--f7-md-primary]"
						onClick={() => setPopupOpen(true)}>
						{t("VIEW ALL MEMBERS")}
					</p>
					<p className="text-[14px] mt-[30px] font-bold text-[--f7-chat-heading-color]">
						{" "}
						{t("GROUP SETTINGS")}
					</p>
					{/* <div
						className="px-[30px] py-[15px] w-full
                flex flex-col gap-[5px]
                rounded-[20px] bg-[--f7-navbar-bg-color] mt-[20px]">
						<p className="text-[16px] font-[400] text-[--f7-heading-color]">
							{t("Edit group information")}
						</p>
						<p className="text-[14px] font-[400] text-[--f7-chat-heading-color]">
							{t(
								"Choose who can change this group's subject, icon, description and messaging settings"
							)}
						</p>
						<List className="group-custom-list mt-[-5px] mb-0  ml-[-20px]">
							<ul>
								<ListItem
									onChange={() => onInputChange("who_can_edit", "all")}
									checked={newGroupData.who_can_edit === "all"}
									name="subject"
									value="all"
									className="text-[16px] text-[--f7-heading-color]"
									radio>
									{t("All members")}
								</ListItem>
								<ListItem
									onChange={() => onInputChange("who_can_edit", "admin")}
									checked={newGroupData.who_can_edit === "admin"}
									name="subject"
									value="admin"
									className="text-[16px] text-[--f7-heading-color]"
									radio>
									{t("Only admins")}
								</ListItem>
							</ul>
						</List>
					</div>
					<div
						className="px-[30px] py-[15px] w-full
                flex flex-col gap-[5px]
                rounded-[20px] bg-[--f7-navbar-bg-color] mt-[20px]">
						<p className="text-[16px] font-[400] text-[--f7-heading-color]">
							{t("Send messages")}
						</p>
						<List className="group-custom-list mt-[-5px] mb-0 ml-[-20px]">
							<ul>
								<ListItem
									onChange={() => onInputChange("who_can_send_message", "all")}
									checked={newGroupData.who_can_send_message === "all"}
									name="send_message"
									value="all"
									className="text-[16px] text-[--f7-heading-color]"
									radio>
									{t("All members")}
								</ListItem>
								<ListItem
									onChange={() =>
										onInputChange("who_can_send_message", "admin")
									}
									checked={newGroupData.who_can_send_message === "admin"}
									name="send_message"
									value="admin"
									className="text-[16px] text-[--f7-heading-color]"
									radio>
									{t("Only admins")}
								</ListItem>
							</ul>
						</List>
					</div> */}
					<div
						className="px-[30px] py-[15px] w-full
                flex  gap-[5px] flex-col
                rounded-[20px] bg-[--f7-navbar-bg-color] mt-[20px]">
						<div
							className="flex items-center justify-between cursor-pointer"
							onClick={() => setAdminPopupOpen(true)}>
							<p className="text-[16px] font-[400] text-[--f7-heading-color]">
								{t("Edit group admins")}
							</p>
						</div>
						{/* <MaterialIcon size={48} icon="chevron_right" fill color="#0e99fe" /> */}
					</div>
					{/* <div
						className="px-[30px] py-[15px] w-full
                flex  gap-[5px] justify-between
                rounded-[20px] bg-[--f7-navbar-bg-color] mt-[20px]">
						<p className="text-[16px] font-[400] text-[--f7-heading-color]">
							{t("Approve new members")}
						</p>
						<Toggle
							checked={newGroupData.approve_new_members === 1}
							onChange={() =>
								onInputChange(
									"approve_new_members",
									newGroupData.approve_new_members === 0 ? 1 : 0
								)
							}
						/>
					</div> */}
					{/* <div
						className="px-[30px] py-[15px] w-full
                flex  gap-[5px] justify-between
                rounded-[20px] bg-[--f7-navbar-bg-color] mt-[20px]">
						<p className="text-[16px] font-[400] text-[--f7-heading-color]">
							{t("Enable Calls")}
						</p>
						<Toggle
							checked={newGroupData.calling_feature === 1}
							onChange={() =>
								onInputChange(
									"calling_feature",
									newGroupData.calling_feature === 0 ? 1 : 0
								)
							}
						/>
					</div> */}
					<div
						className="px-[30px] py-[15px] w-full
                flex  gap-[5px] flex-col
                rounded-[20px] bg-[--f7-navbar-bg-color] mt-[20px]">
						<div className="flex justify-between">
							<p className="text-[16px] font-[400] text-[--f7-heading-color]">
								{t("Enable message forwarding")}
							</p>
							<Toggle
								checked={newGroupData.allow_forwarding === 1}
								onChange={() =>
									onInputChange(
										"allow_forwarding",
										newGroupData.allow_forwarding === 0 ? 1 : 0
									)
								}
							/>
						</div>
						<p className="text-[14px] font-[400] text-[--f7-chat-heading-color]">
							{t(
								"Enabling this option will allow group members to forward messages sent within the group chat to other users/groups"
							)}
						</p>
					</div>
					<div
						className="px-[30px] py-[15px] w-full
                flex  gap-[5px] flex-col
                rounded-[20px] bg-[--f7-navbar-bg-color] mt-[20px]">
						<div className="flex justify-between">
							<p className="text-[16px] font-[400] text-[--f7-heading-color]">
								{t("Enable message sharing")}
							</p>
							<Toggle
								checked={newGroupData.allow_sharing === 1}
								onChange={() =>
									onInputChange(
										"allow_sharing",
										newGroupData.allow_sharing === 0 ? 1 : 0
									)
								}
							/>
						</div>
						<p className="text-[14px] font-[400] text-[--f7-chat-heading-color]">
							{t(
								"Enabling this option will allow group members to share messages sent within the group chat to other messaging platforms outside of KT Messenger"
							)}
						</p>
					</div>
				</div>

				<Button
					onClick={onCreateGroup}
					disabled={isLoading}
					preloader
					loading={isLoading}
					className="fixed bottom-[20px] right-[30px] rounded-full flex
             items-center justify-center active-zoom z-10 w-[60px] h-[60px] bg-[--f7-md-primary] cursor-pointer">
					<MaterialIcon size={48} icon="done" fill color="white" />
				</Button>
			</div>

			<Popup
				id="groupAdminTogglePopup"
				opened={adminPopupOpen}
				onPopupClosed={() => setAdminPopupOpen(false)}>
				<Page>
					<PopupNavbar title={t("Edit group admins")}>
						<NavRight>
							<Button
								small
								className="absolute right-0 top-[-22px]"
								onClick={() => setAdminPopupOpen(false)}>
								<MaterialIcon size={13} icon="close" color="#596365" />
							</Button>
						</NavRight>
					</PopupNavbar>
					<Block>
						{selectedItems?.map((member) => (
							<div
								key={member.id}
								className="flex justify-between items-center py-2">
								<div className="flex items-center">
									<LazyLoaderWraper
										className="w-[55px] h-[55px] rounded-full mr-4"
										src={member.profile_image}
										placeholder={member.image}
									/>
									<span>{member.name}</span>
								</div>
								<Toggle
									checked={newGroupData.admins.includes(member.id)}
									onChange={() => handleAdminToggle(member.id)}
								/>
							</div>
						))}
					</Block>
				</Page>
			</Popup>
			<Popup
				id="groupAdminTogglePopup"
				opened={popupOpen}
				onPopupClosed={() => setPopupOpen(false)}>
				<Page>
					<PopupNavbar title={t("Edit group admins")}>
						<NavRight>
							<Button
								small
								className="absolute right-0 top-[-22px]"
								onClick={() => setPopupOpen(false)}>
								<MaterialIcon size={13} icon="close" color="#596365" />
							</Button>
						</NavRight>
					</PopupNavbar>
					<Block>
						<div className="flex justify-between items-center py-2">
							<div className="flex items-center">
								<LazyLoaderWraper
									className="w-[55px] h-[55px] rounded-full mr-4"
									src={loginResponse?.data?.profile_image}
									placeholder={userimage}
								/>
								<span>{loginResponse?.data?.firstname} (You)</span>
							</div>
						</div>
						{selectedItems?.map((member) => (
							<div
								key={member.id}
								className="flex justify-between items-center py-2">
								<div className="flex items-center">
									<LazyLoaderWraper
										className="w-[55px] h-[55px] rounded-full mr-4"
										src={member.profile_image}
										placeholder={userimage}
									/>
									<span>{member.name}</span>
								</div>
								<Button
									small
									onClick={() =>
										setSelectedItems((prev) =>
											prev.filter((m) => m.id !== member.id)
										)
									}>
									<MaterialIcon size={13} icon="close" color="red" />
								</Button>
							</div>
						))}
					</Block>
				</Page>
			</Popup>
		</Tab>
	);
};

export default NewGroupChat;
