import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { post } from "../../../axios/axiosClient";
import { db } from "../../../js/db";
import Dexie from "dexie";
import { f7 } from "framework7-react";

const initialState = {
	userProfileUpdateResponse: {},
	isLoading: false,
	error: false,
};

export const profileUpdateSlice = createSlice({
	name: "profileUpdate",
	initialState,
	reducers: {
		saveUserProfileUpdateResponse: (state, action) => {
			state.userProfileUpdateResponse = action.payload;
		},
		resetUserProfileUpdateResponse: (state) => {
			state.userProfileUpdateResponse = {};
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(ProfileUpdateService.pending, (state) => {
				state.isLoading = true;
				state.error = null;
			})
			.addCase(ProfileUpdateService.fulfilled, (state, action) => {
				state.isLoading = false;
				if (!action?.payload?.data) return;
				state.userProfileUpdateResponse = action.payload;
				if (typeof action?.payload?.data !== "string") {
					db.users
						.put(
							{
								...(typeof action?.payload?.data !== "string" &&
									action?.payload?.data),
								...action?.payload?.extra,
							},
							{
								id: action?.payload?.data?.id,
							}
						)
						.then(async function (lastKey) {
						})
						.catch(Dexie.BulkError, function (err) {
						});
				} else {
					const uid = parseInt(localStorage.getItem("uid"));
					action?.payload?.extra && db.users.where({ id: uid }).modify((value, ref) => {
						ref.value = {
							...value,
							...action?.payload?.extra,
						};
					}).then((updated) => {
					}).catch((err) => {
					});
				}
			})
			.addCase(ProfileUpdateService.rejected, (state, action) => {
				state.isLoading = false;
				state.error = action.error.message;
			});
	},
});

const ProfileUpdateService = createAsyncThunk(
	"user/profileUpdate",
	async (values) => {
		if (!values) return;

		return post("/user/update_profile", {
			type: "multipart/form-data",
			data: values,
		});
	}
);

export const { saveUserProfileUpdateResponse, resetUserProfileUpdateResponse } =
	profileUpdateSlice.actions;
export { ProfileUpdateService };

export default profileUpdateSlice.reducer;
