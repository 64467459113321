import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { post } from "../../../axios/axiosClient";
import { db } from "../../../js/db";
import Dexie from "dexie";
import { encrypt } from "../../../utils/crypto";
const initialState = {
	userContactsResponse: {},
	isLoading: false,
	error: false,
};

export const contactSlice = createSlice({
	name: "contacts",
	initialState,
	reducers: {
		saveUserContactsResponse: (state, action) => {
			state.userContactsResponse = action.payload;
		},
		resetUserContactsResponse: (state) => {
			state.userContactsResponse = {};
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(userContactsService.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(userContactsService.fulfilled, (state, action) => {
				state.isLoading = false;
				if (!action.payload?.data?.contacts_list) return;
				state.userContactsResponse = action.payload;
				// storing contacts in index db
				db.users
					.bulkPut(action.payload?.data?.contacts_list)
					.then(async function (lastKey) {
					})
					.catch(Dexie.BulkError, function (err) {
					});
			})
			.addCase(userContactsService.rejected, (state, action) => {
				state.isLoading = false;
				state.error = action.error.message;
			});
	},
});

const userContactsService = createAsyncThunk(
	"user/userContactsService",
	async (values) => {
		if (!values) return;

		return post("/user/get_user_contacts", {
			type: "application/x-www-form-urlencoded",
			data: { encodedParams: await encrypt(JSON.stringify(values)) },
		});
	}
);

export const { saveUserContactsResponse, resetUserContactsResponse } =
	contactSlice.actions;
export { userContactsService };

export default contactSlice.reducer;
