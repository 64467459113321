import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { post } from "../../../axios/axiosClient";
import { encrypt } from "../../../utils/crypto";
import { db } from "../../../js/db";
import Dexie from "dexie";

const initialState = {
	pendingRequestsResponse: {},
	isLoading: false,
	error: false,
};

export const pendingRequestsSlice = createSlice({
	name: "pendingRequests",
	initialState,
	reducers: {
		savePendingRequestsResponse: (state, action) => {
			state.pendingRequestsResponse = action.payload;
		},
		resetPendingRequestsResponse: (state) => {
			state.pendingRequestsResponse = {};
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(pendingRequestsService.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(pendingRequestsService.fulfilled, (state, action) => {
				state.isLoading = false;
				if (!action?.payload?.data) return;
				state.pendingRequestsResponse = action.payload;
				// storing friendRequests in index db
				db.friendRequests.toArray().then((exData) => {
					const data = action?.payload?.data
						.filter((a) => !exData.map((b) => b.id).includes(a.id))
						.map((a) => ({ ...a, is_read: 0 }));

					data &&
						data?.length !== 0 &&
						db.friendRequests
							.bulkPut(data)
							.then(async function (lastKey) {
							})
							.catch(Dexie.BulkError, function (err) {
							});
				});
			})
			.addCase(pendingRequestsService.rejected, (state, action) => {
				state.isLoading = false;
				state.error = action.error.message;
			});
	},
});

const pendingRequestsService = createAsyncThunk(
	"user/pendingRequests",
	async (values) => {
		if (!values) return;

		return post("/user/get_pending_requests", {
			type: "application/x-www-form-urlencoded",
			data: { encodedParams: await encrypt(JSON.stringify(values)) },
		});
	}
);

export const { savePendingRequestsResponse, resetPendingRequestsResponse } =
	pendingRequestsSlice.actions;
export { pendingRequestsService };

export default pendingRequestsSlice.reducer;
