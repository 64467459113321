import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import resourcesToBackend from "i18next-resources-to-backend"; //use https://github.com/i18next/i18next-chained-backend if other backends needs to be added as fallback
import { initReactI18next } from "react-i18next";

i18n
	.use(LanguageDetector)
	.use(initReactI18next)
	.use(
		resourcesToBackend((language, namespace) =>
			import(`./locales/${language}/${namespace}.json`)
		)
	)
	.init({
		fallbackLng: "en",
		// ...(process.env.NODE_ENV === "development" && { debug: true }),
		debug: false,

		// have a common namespace used around the full app
		ns: ["translations"],
		defaultNS: "translations",
		detection: { convertDetectedLanguage: (lng) => lng.replace(/\-\w+/g, "") },

		keySeparator: false, // we use content as keys

		interpolation: {
			escapeValue: false,
		},
		react: {
			useSuspense: false,
		},
	});

export default i18n;
