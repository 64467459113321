import {
	Link,
	NavLeft,
	NavTitle,
	Navbar,
	Tab,
	ListItem,
	List,
	Button,
} from "framework7-react";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import MaterialIcon from "../../../../../components/misc/materialIcon";
import { useSelector } from "react-redux";
import { SocketContext } from "../../../../../socket";
import { ENDPOINTS } from "../../../../../constants/socket";
import { db } from "../../../../../js/db";

const BlockedContact = () => {
	const { t } = useTranslation();
	const socket = useContext(SocketContext);
	const { loginResponse } = useSelector((state) => state.loginSlice);

	const [blockedContacts, setBlockedContacts] = useState([]);

	useEffect(() => {
		if (loginResponse?.data?.user_id) {
			getBlockedList();
		}
	}, [loginResponse, socket]);

	const getBlockedList = () => {
		socket?.emit(
			ENDPOINTS.GET_BLOCK_LIST,
			JSON.stringify({
				user_id: loginResponse.data.user_id,
			}),
			(response) => {
				setBlockedContacts(response.data);
			}
		);
	};

	const handleUnblock = (block_id) => {
		socket?.emit(
			ENDPOINTS.UNBLOCK_CONTACT,
			JSON.stringify({
				user_id: loginResponse.data.user_id,
				block_id: block_id,
			}),
			(response) => {
				db.users.where({ id: block_id }).modify({ blocked: 0 });
				setBlockedContacts((prev) =>
					prev.filter((contact) => contact.block_id !== block_id)
				);
			}
		);
	};

	return (
		<Tab id="BlockedContacts" onTabShow={getBlockedList}>
			<Navbar>
				<NavLeft>
					<Link tabLink="#settingsTab">
						<MaterialIcon
							size={48}
							icon="arrow_back"
							fill
							color="--f7-chat-heading-color"
						/>
					</Link>
				</NavLeft>
				<NavTitle className="text-[--f7-heading-color] font-bold">
					{t("Blocked Contacts")}
				</NavTitle>
			</Navbar>
			{blockedContacts.length > 0 ? (
				<List
					mediaList
					strongIos
					className="search-list contact-list mt-[0px] searchbar-found">
					{blockedContacts.map((contact) => (
						<ListItem
							key={contact.block_id}
							className="cursor-pointer friends-li align-center">
							<img
								slot="media"
								src={
									contact.hide_profile_picture
										? "https://storage.googleapis.com/kalaamtime/noimage.png"
										: contact.profile_image
								}
								alt={contact.name}
								className="rounded-full align-bottom"
								width={55}
								height={55}
							/>
							<div className="item-inner text-[--f7-heading-color] font-bold text-[14px] md:text-[16px] items-center h-full flex">
								<p className="pt-1">{contact.name}</p>
								<Button
									fill
									small
									className="ml-2"
									onClick={() => handleUnblock(contact.block_id)}>
									{t("Unblock")}
								</Button>
							</div>
						</ListItem>
					))}
				</List>
			) : (
				<List className="">
					<ListItem title={t("No Blocked Contacts")} />
				</List>
			)}
		</Tab>
	);
};

export default BlockedContact;
