import ForgetPasswordPage from "../pages/forgetPassword";
import SplashPage from "../pages/splash";
import LoginPage from "../pages/login";
import LoginWithPosh from "../pages/login-with-posh";
import SignupPage from "../pages/signup";
import PinPage from "../pages/pinPage";
import ChatsPage from "../pages/chats";
import ViaSms from "../pages/ViaSms";
import Viaemail from "../pages/Viaemail";
import ViaPIN from "../pages/ViaPIN";
import NextToEmail from "../pages/NextToEmail";
import checkEmail from "../pages/checkEmail";
import CreateNewPasswordEmail from "../pages/CreateNewPasswordEmail";
import CreateNewPasswordNumber from "../pages/CreateNewPasswordNumber";
import ResetPassword from "../pages/ResetPassword";
import SingleChatPage from "../pages/singleChat";
import { getCookie } from "../utils/functions";
import { AUTHTOKEN_COOKIE } from "../constants";
import CreatePin from "../pages/createPin";
import ProfilePage from "../pages/profileImage";
import NextToNumber from "../pages/NextToNumber";
import AutoPoshLogin from "../pages/login-with-posh/autoPoshLogin";
import StoriesPage from "../pages/StoriesPage";

var routes = [
	{
		path: "/",
		name: "home",
		redirect: function ({ to, resolve, reject }) {
			const token = getCookie(AUTHTOKEN_COOKIE);
			const urlParams = new URLSearchParams(window.location.search);

			const poshid = urlParams.get("poshid");
			const wntoken = urlParams.get("wntoken");
			const poshAccessToken = urlParams.get("app_access_token");

			if (
				window.location.search.length > 0 &&
				poshid &&
				wntoken &&
				poshAccessToken
			) {
				resolve(`/LoginWithPosh/auto/${poshid}/${wntoken}/${poshAccessToken}`);
			} else if (token) {
				resolve(
					`${window.innerWidth < 775 ? "/chats/" : "/chats/null/single"}`
				);
			} else {
				resolve("/login/");
			}
		},
	},
	{
		path: "/ProfilePage",
		name: "/ProfilePage",
		component: ProfilePage,
		// Routes: [
		// 	{
		// 		path: "ProfilePage/CreatePin",
		// 		name: 'CreatePin',
		// 		component: CreatePin,
		// 	},
		// ],
	},
	{
		path: "/CreatePin",
		name: "/CreatePin",
		component: CreatePin,
	},
	{
		path: "/splash",
		name: "spalsh",
		component: SplashPage,
	},
	{
		path: "/login",
		name: "login",
		component: LoginPage,
	},
	{
		path: "/LoginWithPosh",
		name: "loginwithposh",
		component: LoginWithPosh,
		routes: [
			{
				path: "/auto/:poshid/:wntoken/:poshAccessToken",
				name: "autoPoshLogin",
				component: AutoPoshLogin,
			},
		],
	},
	{
		path: "/ViaSms",
		name: "ViaSms",
		component: ViaSms,
	},
	{
		path: "/Viaemail",
		name: "Viaemail",
		component: Viaemail,
	},
	{
		path: "/ViaPIN",
		name: "ViaPIN",
		component: ViaPIN,
	},
	{
		path: "/NextToEmail",
		name: "NextToEmail",
		component: NextToEmail,
	},
	{
		path: "/NextToNumber",
		name: "NextToNumber",
		component: NextToNumber,
	},
	{
		path: "/signup",
		name: "signup",
		component: SignupPage,
	},
	{
		path: "/pinPage",
		name: "pinPage",
		component: PinPage,
	},
	{
		path: "/checkEmail",
		name: "checkEmail",
		component: checkEmail,
	},
	{
		path: "/CreateNewPasswordNumber",
		name: "/CreateNewPasswordNumber",
		component: CreateNewPasswordNumber,
	},
	{
		path: "/CreateNewPasswordEmail",
		name: "/CreateNewPasswordEmail",
		component: CreateNewPasswordEmail,
	},
	{
		path: "/ResetPassword",
		name: "/ResetPassword",
		component: ResetPassword,
	},
	{
		path: "/chats",
		name: "chats",
		master: true,
		component: ChatsPage,
		keepAlive: true,
		detailRoutes: [
			{
				path: "/chats/:id/:type",
				component: SingleChatPage,
				// keepAlive: true,
			},
			{
				path: "/stories/:id",
				component: StoriesPage,
			},
		],
	},
	{
		path: "/ForgetPasswordPage",
		name: "ForgetPasswordPage",
		component: ForgetPasswordPage,
	},
];

export default routes;
