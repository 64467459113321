import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { post } from "../../../axios/axiosClient";
import { encrypt } from "../../../utils/crypto";

const initialState = {
	createGroupResponse: {},
	isLoading: false,
	error: false,
};

export const createGroupSlice = createSlice({
	name: "createGroup",
	initialState,
	reducers: {
		saveCreateGroupResponse: (state, action) => {
			state.createGroupResponse = action.payload;
		},
		resetCreateGroupResponse: (state) => {
			state.createGroupResponse = {};
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(createGroupService.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(createGroupService.fulfilled, (state, action) => {
				state.isLoading = false;
				if (!action.payload) return;
				state.createGroupResponse = action.payload;
				// action.payload.data?.chat_id && db.chats.put(action.payload.data);
			})
			.addCase(createGroupService.rejected, (state, action) => {
				state.isLoading = false;
				state.error = action.error.message;
			});
	},
});

const createGroupService = createAsyncThunk(
	"chat/createGroup",
	async (values) => {
		if (!values) return;

		return post("/chat/create_group", {
			type: "application/json",
			data: { encodedParams: await encrypt(JSON.stringify(values)) },
		})
	}
);

export const { saveCreateGroupResponse, resetCreateGroupResponse } = createGroupSlice.actions;
export { createGroupService };

export default createGroupSlice.reducer;
