import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { post } from "../../../axios/axiosClient";
import { db } from "../../../js/db";
import Dexie from "dexie";
import {  encrypt } from "../../../utils/crypto";

const initialState = {
	userProfileResponse: {},
	isLoading: false,
	error: false,
};

export const profileSlice = createSlice({
	name: "profile",
	initialState,
	reducers: {
		saveUserProfileResponse: (state, action) => {
			state.userProfileResponse = action.payload;
		},
		resetUserProfileResponse: (state) => {
			state.userProfileResponse = {};
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(userProfileService.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(userProfileService.fulfilled, (state, action) => {
				state.isLoading = false;
				if (!action.payload?.data[0]?.id) return;

				state.userProfileResponse = action.payload;
				if (action.payload?.data[0]?.id > 1) {
					// excluding the admin account
					db.users
						.put(action.payload?.data[0], {
							id: action.payload?.data[0]?.id,
						})
						.then(function (lastKey) {
						})
						.catch(Dexie.BulkError, function (err) {
						});
				}
			})
			.addCase(userProfileService.rejected, (state, action) => {
				state.isLoading = false;
				state.error = action.error.message;
			});
	},
});

const userProfileService = createAsyncThunk(
	"user/userProfile",
	async (values) => {
		if(!values) return;
		return post("/user/profile", {
			type: "application/x-www-form-urlencoded",
			data: { encodedParams: await encrypt(JSON.stringify(values)) },
		});
	}
);

export const { saveUserProfileResponse, resetUserProfileResponse } =
	profileSlice.actions;
export { userProfileService };

export default profileSlice.reducer;
