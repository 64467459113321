import {
	Link,
	NavLeft,
	NavTitle,
	Navbar,
	Tab,
	List,
	ListItem,
	PageContent,
	Preloader,
	f7,
} from "framework7-react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
// Import icons from react-icons for browser/OS specific icons
import {
	FaChrome,
	FaFirefox,
	FaSafari,
	FaEdge,
	FaOpera,
	FaWindows,
	FaApple,
	FaAndroid,
	FaLinux,
} from "react-icons/fa";
import MaterialIcon from "../../../../../components/misc/materialIcon";
import {
	LoggedDeviceService,
	DeviceLogoutService,
} from "../../../../../redux/features/userSlice/devices";
import moment from "moment";

const LoggedDevices = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { LoggedDeviceResponse, isLoading, error } = useSelector(
		(state) => state.LoggedDeviceSlice
	);
	const handleGetLoggedDevices = () => {
		dispatch(LoggedDeviceService({}));
	};

	return (
		<Tab id="LoggedDevices" onTabShow={handleGetLoggedDevices}>
			<Navbar>
				<NavLeft>
					<Link tabLink="#settingsTab">
						<MaterialIcon
							size={48}
							icon="arrow_back"
							fill
							color="--f7-chat-heading-color"
						/>
					</Link>
				</NavLeft>
				<NavTitle className="text-[--f7-heading-color] font-bold">
					{t("Logged in Devices")}
				</NavTitle>
			</Navbar>
			<PageContent className="pt-0.5 flex flex-col">
				{Object.keys(LoggedDeviceResponse).length > 0 ? (
					<List className="m-[16px]" mediaList menuList>
						{LoggedDeviceResponse?.data?.map((device) => (
							<ListItem
								key={device.id}
								className={`rounded-md ${
									device.current
										? "cursor-default"
										: "cursor-pointer hover:bg-gray-100"
								}`}
								onClick={() => {
									if (device.current) return;
									f7.dialog.confirm(
										"Are you sure you want to logout from this device?",
										() => {
											dispatch(
												DeviceLogoutService({
													session_key: device.session_key,
												})
											);
										}
									);
								}}>
								<div slot="media">
									{device.device_type === "web" &&
									device.browser !== "Chromium" ? (
										device.browser === "Chrome" ? (
											<FaChrome
												size={24}
												color="#0e99fe"
												className="mr-[15px]"
											/>
										) : device.browser === "Firefox" ? (
											<FaFirefox
												size={24}
												color="#0e99fe"
												className="mr-[15px]"
											/>
										) : device.browser === "Safari" ? (
											<FaSafari
												size={24}
												color="#0e99fe"
												className="mr-[15px]"
											/>
										) : device.browser === "Edge" ? (
											<FaEdge size={24} color="#0e99fe" className="mr-[15px]" />
										) : device.browser === "Opera" ? (
											<FaOpera
												size={24}
												color="#0e99fe"
												className="mr-[15px]"
											/>
										) : (
											<MaterialIcon
												icon="language"
												size={24}
												color="#0e99fe"
												className="mr-[15px]"
											/>
										)
									) : device.platform === "Microsoft Windows" ? (
										<FaWindows
											size={24}
											color="#0e99fe"
											className="mr-[15px]"
										/>
									) : device.platform.toLowerCase().includes("apple") ||
									  device.platform.toLowerCase().includes("ios") ||
									  device.platform.toLowerCase().includes("mac") ? (
										<FaApple size={24} color="#0e99fe" className="mr-[15px]" />
									) : device.platform === "Android" ? (
										<FaAndroid
											size={24}
											color="#0e99fe"
											className="mr-[15px]"
										/>
									) : device.platform.toLowerCase().includes("linux") ? (
										<FaLinux size={24} color="#0e99fe" className="mr-[15px]" />
									) : (
										<MaterialIcon
											icon="devices"
											size={24}
											color="#0e99fe"
											className="mr-[15px]"
										/>
									)}
								</div>
								<div slot="title">
									{device.device_type === "web" && device.browser !== "Chromium"
										? `${device.browser} on ${
												device.device_os.toLowerCase().includes("windows")
													? "Windows"
													: device.device_os.toLowerCase().includes("os x")
													? "MacOS"
													: device.device_os || device.platform
										  } (Web)`
										: device.device_os.toLowerCase().includes("windows")
										? "Windows"
										: device.device_os.toLowerCase().includes("os x")
										? "MacOS"
										: device.device_os || device.platform}
									{device.current && (
										<span className="text-primary font-bold"> (Current)</span>
									)}
								</div>
								<div slot="text" className="text-gray-500 text-xs">
									Last seen at{" "}
									{moment(device.updated_at).format("DD MMMM YYYY h:mm a")}
								</div>
							</ListItem>
						))}
					</List>
				) : isLoading ? (
					<div className="flex w-full h-full items-center justify-center">
						<Preloader color="blue" size={40} />
					</div>
				) : (
					<div className="flex w-full h-full items-center justify-center">
						{t("No devices found")}
					</div>
				)}
			</PageContent>
		</Tab>
	);
};

export default LoggedDevices;
