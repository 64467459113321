import React, { useContext, useEffect, useState } from "react";
import { ENDPOINTS } from "../../../constants/socket";
import { SocketContext } from "../../../socket";
import { db } from "../../../js/db";
import { useTranslation } from "react-i18next";
import { Notification } from "iconsax-react";
import { Toggle, useStore } from "framework7-react";
import { useUpdateEffect } from "react-use";

const MuteChatWidget = ({ chat_id, user_id = null, checked = false }) => {
	const socket = useContext(SocketContext);
	const { t } = useTranslation();
    chat_id = chat_id !== "posh_ai" ? parseInt(chat_id) : chat_id;
    checked = checked ? true : false;
	const activeConversation = useStore("activeConversation");
	if (activeConversation) {
		chat_id =
			activeConversation !== "null"
				? activeConversation !== "posh_ai"
					? parseInt(activeConversation)
					: activeConversation
				: activeConversation;
	}
    const [muteChecked, setMuteChecked] = useState(false);
    
    useUpdateEffect(() => {
        setMuteChecked(checked);
    }, [checked])

	return (
		<div
			className="px-[30px] py-[15px] w-full
                                            rounded-[20px] bg-[--f7-navbar-bg-color] mt-[20px]">
			<div className="flex items-start gap-[20px]">
				<div className="min-w-[24px] min-h-[24px] ">
					<Notification color={"#0e99fe"} variant="Bold" icon="mail" />
				</div>
				<div className="flex-1">
					<div className="flex justify-between w-full">
						<p className="text-[16px] font-[400] text-[--f7-heading-color]">
							{t("Mute Notifications")}
						</p>
						<Toggle
                            onToggleChange={(e) => {
                                setMuteChecked(!e);
								socket?.emit(
									ENDPOINTS.TOGGLE_MUTE_NOTIFICATIONS,
									JSON.stringify({
										chat_ids: [chat_id],
										mute: !e,
									}),
									async (response) => {
										db.chats
											.where({
												chat_id: chat_id,
											})
											.modify({
												is_muted: response.mute,
											});
										user_id &&
											db.users
												.where({
													id: user_id,
												})
												.modify({
													is_mute: response.mute,
												});
									}
								);
							}}
							checked={muteChecked}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default MuteChatWidget;
