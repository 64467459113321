import { Block, Button, Popover, Range } from "framework7-react";
import {
  Add,
  AddCircle,
  ArrowUp,
  Brush2,
  BrushBig,
  Eraser,
  Minus,
} from "iconsax-react";
import React, { useRef, useState } from "react";
import { CirclePicker } from "react-color";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import useEyeDropper from "use-eye-dropper";
import {
  updateCurserSIze,
  updateDoodleColor,
  updateMode,
} from "../../../redux/features/storiesSlice/userStory";
import MaterialIcon from "../../misc/materialIcon";

function AddDoodlesMenu({
  enableDrawingMode,
  disableDrawingMode,
  enableEraserMode,
}) {
  const { t: translate } = useTranslation();
  const [FontColorPicker, setFontColorPicker] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const { open, close, isSupported } = useEyeDropper();
  const inputColorRef = useRef();
  const dispatch = useDispatch();
  const { curserSize } = useSelector((store) => store?.userStory);

  return (
    <Popover
      className="AddDoodlesMenuOs6 min-w-[320px] ml-[0px] bg-[#F1F1F1] p-4"
      bgColor="rgba(128, 128, 128, 0.3)"
      verticalPosition="auto"
      style={{ width: "409px" }}
      arrow={false}
      backdrop={true}
    >
      <Block className="flex flex-col w-[auto] gap-4 p-0 m-0">
        <Block className="flex justify-between items-center w-[100%] m-0 p-0  ">
         
          <p className="text-[18px] font-medium m-0">{translate("Doodles")}</p>
          <Button
            className="w-[50px] h-[50px]"
            popoverClose=".AddDoodlesMenuOs6"
          >
            <Add size={40} color="#5D6980" className="transform rotate-45" />
          </Button>
        </Block>
        <Block className="flex gap-4 p-0 flex-wrap m-0">
          <Button
            onClick={() => {
              if (selectedIndex === 0) {
                setSelectedIndex(null);
                disableDrawingMode();
              } else {
                enableDrawingMode();
                setSelectedIndex(0);
              }
            }}
            style={{
              backgroundColor: selectedIndex === 0 ? "#0E99FE" : "#F6F6F6",
            }}
            className="border border-[#E0E7EC]  w-[45px] h-[45px] rounded-[16px] flex justify-center items-center"
          >
            {selectedIndex === 0 ? (
              <Brush2
                onClick={enableDrawingMode}
                size="24"
                color="#fff"
                variant="Bold"
              />
            ) : (
              <Brush2
                onClick={disableDrawingMode}
                size="24"
                color="#5D6980"
                variant="Bold"
              />
            )}
          </Button>
          <Button
            onClick={() =>
              selectedIndex === 1 ? setSelectedIndex(null) : setSelectedIndex(1)
            }
            style={{
              backgroundColor: selectedIndex === 1 ? "#0E99FE" : "#F6F6F6",
            }}
            className="border border-[#E0E7EC]  w-[45px] h-[45px] rounded-[16px] flex justify-center items-center"
          >
            {selectedIndex === 1 ? (
              <BrushBig size="24" color="#fff" variant="Bold" />
            ) : (
              <BrushBig size="24" color="#5D6980" variant="Bold" />
            )}
          </Button>
          <Button
            onClick={() =>
              selectedIndex === 2 ? setSelectedIndex(null) : setSelectedIndex(2)
            }
            style={{
              backgroundColor: selectedIndex === 2 ? "#0E99FE" : "#F6F6F6",
            }}
            className="border border-[#E0E7EC]  w-[45px] h-[45px] rounded-[16px] flex justify-center items-center"
          >
            {selectedIndex === 2 ? (
              <ArrowUp size="24" color="#fff" variant="Outline" />
            ) : (
              <ArrowUp size="24" color="#5D6980" variant="Outline" />
            )}
          </Button>
          <Button
            onClick={() => {
              if (selectedIndex === 3) {
                setSelectedIndex(null);
                disableDrawingMode();
              } else {
                enableEraserMode();
                setSelectedIndex(3);
              }
            }}
            style={{
              backgroundColor: selectedIndex === 3 ? "#0E99FE" : "#F6F6F6",
            }}
            className="border border-[#E0E7EC]  w-[45px] h-[45px] rounded-[16px] flex justify-center items-center"
          >
            {selectedIndex === 3 ? (
              <Eraser size="24" color="#fff" variant="Bold" />
            ) : (
              <Eraser size="24" color="#5D6980" variant="Bold" />
            )}
          </Button>
        </Block>
        <Block className="border w-full p-0 m-0 border-[#E0E7EC] rounded-[50px] bg-[#F6F6F6] h-[45px] flex justify-between px-[1rem] items-center">
          <div className="w-[20px] h-[20px] p-0 m-0 cursor-pointer">
            <Minus size="24" color="#5D6980" variant="Outline" />
          </div>
          <div style={{ flex: 1, margin: "0 16px" }} className="flex-1">
            <Range
              min={0}
              max={100}
              step={1}
              value={curserSize}
              onRangeChange={(e) => {
                dispatch(updateCurserSIze(e));
              }}
            />
          </div>
          <div className="w-[20px] h-[20px] p-0 m-0 cursor-pointer">
            <Add size="24" color="#5D6980" variant="Outline" />
          </div>
        </Block>
        <Block className="border p-4 w-[100%] rounded-[20px] m-0 border-[#E0E7EC] bg-[#F6F6F6]">
          <p className="text-[14px] font-bold mb-2">
            {translate("Font Color")}
          </p>
          <CirclePicker
            onChange={(e) => {
              setSelectedIndex(0);
              dispatch(updateDoodleColor(e.hex));
              dispatch(updateMode("D"));
            }}
          />
          <p className="text-[14px] font-bold mt-2">{translate("Custom")}</p>
          <Block className="flex justify-start items-center gap-2 m-0 p-0 mt-1">
            <AddCircle
              className="cursor-pointer"
              size="20"
              color="#5D6980"
              variant="Outline"
              onClick={() => inputColorRef.current.click()}
            />
            <div
              onClick={async () => {
                if (isSupported()) {
                  try {
                    const pickedColor = await open();
                    setSelectedIndex(0);
                    dispatch(updateDoodleColor(pickedColor.sRGBHex));
                    dispatch(updateMode("D"));
                  } catch (error) {
                  }
                } else {
                }
              }}
              className="cursor-pointer flex justify-center items-center"
            >
              <MaterialIcon
                className={"text-[28px]"}
                icon="colorize"
                fill
                color="#5D6980"
              />
            </div>

            <input
              ref={inputColorRef}
              onChange={(e) => {
                dispatch(updateDoodleColor(e.target.value));
                setSelectedIndex(0);
                dispatch(updateMode("D"));
              }}
              type="color"
              className="absolute top-0 left-0 opacity-0 z-[-1]"
            />
          </Block>
        </Block>
      </Block>
    </Popover>
  );
}

export default AddDoodlesMenu;
