import { useLiveQuery } from "dexie-react-hooks";
import { useStore } from "framework7-react";
import React, { useState } from "react";
import { db } from "../../../js/db";
import LazyLoaderWraper from "../../misc/lazyloaderWraper";
import { useTranslation } from "react-i18next";
import MaterialIcon from "../../misc/materialIcon";

const ChatMediaWidget = ({ chat_id, onClick = () => {} }) => {
	chat_id = chat_id !== "posh_ai" ? parseInt(chat_id) : chat_id;
	const { t } = useTranslation();
	const activeConversation = useStore("activeConversation");
	const [mediaCount, setMediaCount] = useState(0);
	if (activeConversation) {
		chat_id =
			activeConversation !== "null"
				? activeConversation !== "posh_ai"
					? parseInt(activeConversation)
					: activeConversation
				: activeConversation;
	}

	const media = useLiveQuery(
		async () => {
			if (!chat_id || chat_id === "null") return [];
			let data = await db.messages
				.where({ chat_id: chat_id, type: "image" })
				.toArray();
			setMediaCount(data?.length);
			data.sort((a, b) => b.unix_time - a.unix_time);
			data = data.slice(0, 4);
			return data;
		},
		[chat_id],
		[]
	);
	return (
		<>
			<div className="flex justify-between ">
				<p className="text-[14px] text-[--f7-chat-heading-color] font-[800]">
					{t("MEDIA, LINKS AND DOCUMENTS")}
				</p>
				<div
					className="flex items-center cursor-pointer"
					onClick={() => mediaCount > 0 ? onClick(media[0]?.id) : () => {}}>
					<p
						className={`text-[14px] ${
							mediaCount > 0 && "text-[--f7-md-primary]"
						} font-[800]`}>
						{mediaCount}
					</p>
					{mediaCount > 0 && (
						<MaterialIcon color={"#0e99fe"} icon="chevron_right" />
					)}
				</div>
			</div>
			{media && media?.length > 0 && (
				<div className="flex items-center overflow-hidden w-full mt-4 gap-[10px]">
					{media?.map((item, index) => (
						<div key={index}>
							<LazyLoaderWraper
								src={item.thumbnail}
								placeholder={item.thumbnail}
								height={80}
								width={80}
								alt=""
								className="rounded-[10px] align-bottom cursor-pointer object-cover"
								wrapperclassname="rounded-[10px] align-bottom cursor-pointer"
								onClick={() => onClick(item.id)}
							/>
						</div>
					))}
				</div>
			)}
		</>
	);
};

export default ChatMediaWidget;
