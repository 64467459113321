// Import React and ReactDOM
import React from "react";
import { createRoot } from "react-dom/client";

// Import Framework7
import Framework7 from "framework7/lite-bundle";

// Import Framework7-React Plugin
import Framework7React from "framework7-react";

// Import Framework7 Styles
import "framework7/css/bundle";

// Import Icons and App Custom Styles
import "../assets/css/googleFonts.css";
import "../assets/css/icons.css";
import "../assets/css/app.css";
import "../assets/css/kt-icons.css";
import "leaflet/dist/leaflet.css";

// Import App Component
import App from "../components/app.jsx";
import { handshake } from "../utils/handShake.js";

const customLogger = () => {};
if (import.meta.env.MODE === "production") {
	if(!window.location.host.includes("localhost")) console.log = console.time = console.timeEnd = customLogger; //Disable console log in production
	document.addEventListener("contextmenu", function (event) {
		if (
			event.target.tagName.toLowerCase() === "input" ||
			event.target.tagName.toLowerCase() === "textarea"
		) {
			return;
		}
		event.preventDefault();
	});
}
// Init F7 React Plugin
Framework7.use(Framework7React);
// Mount React App
addEventListener("load", async (event) => {
	try {
		await handshake();
		const root = createRoot(document.getElementById("app"));
		root.render(React.createElement(App));
	} catch (ex) {
	}
});
