import React, { useCallback, useContext, useEffect, useState } from "react";
import { SocketContext } from "../../../socket";
import { useTranslation } from "react-i18next";
import { Toggle, useStore } from "framework7-react";
import { Send } from "iconsax-react";
import { ENDPOINTS } from "../../../constants/socket";
import { useUpdateEffect } from "react-use";
import { db } from "../../../js/db";

const UserForwordSettingWidget = ({
	chat_id,
	user_id = null,
	checked = false,
	subtitle = "",
}) => {
	const socket = useContext(SocketContext);
	const { t } = useTranslation();
	chat_id = chat_id !== "posh_ai" ? parseInt(chat_id) : chat_id;
	checked = checked ? true : false;
	const activeConversation = useStore("activeConversation");
	if (activeConversation) {
		chat_id =
			activeConversation !== "null"
				? activeConversation !== "posh_ai"
					? parseInt(activeConversation)
					: activeConversation
				: activeConversation;
	}
	const [forwardChecked, setForwardChecked] = useState(false);

	useEffect(() => {
		setForwardChecked(checked ? true : false);
	}, [checked]);

	const handleUpdate = (checked) => {
		socket.emit(
			ENDPOINTS.CHANGE_INDIVIDUAL_FORWARDING,
			JSON.stringify({ chat_id, new_value: checked ? 1 : 0 }), async (response) =>{
				console.log(response);
				db.chats.where({ chat_id }).modify((value, ref) => {
					ref.value = {
						...value,
						allow_forwarding: response.new_value,
					};
				});
			}
		);
	}

	return (
		<div
			className="px-[30px] py-[15px] w-full
                                            rounded-[20px] bg-[--f7-navbar-bg-color] mt-[20px]">
			<div className="flex items-start gap-[20px]">
				<div className="min-w-[24px] min-h-[24px] ">
					<Send color={"#0e99fe"} variant="Bold" />
				</div>
				<div>
					<div className="flex justify-between">
						<p className="text-[16px] font-[400] text-[--f7-heading-color]">
							{t("Enable Forwarding of my messages")}
						</p>
						<Toggle
							onToggleChange={(e) => {
								setForwardChecked(!e);
								handleUpdate(!e);
							}}
							checked={forwardChecked}
						/>
					</div>
					<p className="text-[14px] font-[400] mt-[10px] text-[--f7-chat-heading-color]">
						{t(subtitle)}
					</p>
				</div>
			</div>
		</div>
	);
};

export default UserForwordSettingWidget;
