import { f7, Link } from "framework7-react";
import React, { useEffect, useState } from "react";
import reactStringReplace from "react-string-replace";
import MarkdownPreview from "@uiw/react-markdown-preview";
import { useDispatch } from "react-redux";
import { db } from "../js/db";
import { userProfileService } from "../redux/features/userSlice/profile";
import rehypeSanitize from "rehype-sanitize";

const useParsedMessage = (message, members, containsMarkdown) => {
	const dispatch = useDispatch();
	const [parsedString, setParsedString] = useState(message);

	useEffect(() => {
		if (!message) return;

		(async () => {
			if (message.startsWith("@")) {
				message = ` ${message}`;
			}

			const urlRegex = /(https?:\/\/\S+)/g;
			const mentionRegex = /@\[([^\]]+)\]\((\d+)\)/g;

			let fallbackName = {};
			let tempParsedString = message.replace(
				mentionRegex,
				(match, name, id) => {
					fallbackName[id] = name;
					return `@${id}`;
				}
			);

			// Handle URLs first (synchronously)
			tempParsedString = reactStringReplace(
				tempParsedString,
				urlRegex,
				(match, i) => (
					<Link
						key={match + i}
						external
						className="text-primary"
						href={match}
						target="_blank"
						rel="noopener noreferrer">
						{match}
					</Link>
				)
			);

			// Handle mentions, including async user fetching
			const promises = [];
			tempParsedString = reactStringReplace(
				tempParsedString,
				/\s@(\d+)/g,
				(match, i) => {
					const member = members?.find(
						(member) => member.id === parseInt(match)
					);
					if (member) {
						return (
							<Link
								key={match + i}
								href="#"
								className="text-primary"
								onClick={() => {
									dispatch(userProfileService({ user_id: parseInt(match) }));
									f7.store.dispatch("setSelectedUserId", parseInt(match));
								}}>
								{`@${
									member.nickname || member.firstname + " " + member.lastname
								}`}
							</Link>
						);
					} else {
						// Placeholder for async result
						promises.push(
							db.users.get({ id: parseInt(match) }).then((contact) => {
								if (contact) {
									return {
										match: match,
										jsx: (
											<Link
												key={match + i}
												href="#"
												className="text-primary"
												onClick={() => {
													dispatch(
														userProfileService({ user_id: parseInt(match) })
													);
													f7.store.dispatch(
														"setSelectedUserId",
														parseInt(match)
													);
												}}>
												{`@${contact.nickname || contact.name}`}
											</Link>
										),
									};
								} else if (fallbackName[match]) {
									return fallbackName[match].toString();
								}
								return `@${match}`;
							})
						);
						return `@${match}`; // Placeholder until async resolves
					}
				}
			);

			// Wait for all async operations to resolve
			const resolvedMentions = await Promise.all(promises);

			resolvedMentions?.map((resolvedMention, i) => {
				if (message.startsWith("in the new build,"))
					tempParsedString = reactStringReplace(
						tempParsedString,
						`@${resolvedMention.match}`,
						(match, i) => resolvedMention.jsx
					);
			});

			// Markdown handling
			for (var index in tempParsedString) {
				if (
					typeof tempParsedString[index] === "string" &&
					containsMarkdown(tempParsedString[index])
				) {
					tempParsedString[index] = (
						<MarkdownPreview
							source={tempParsedString[index]}
							rehypePlugins={[rehypeSanitize]}
						/>
					);
				}
			}

			// Set final parsed string after async resolves
			setParsedString(tempParsedString);
		})();
	}, [message, members]);

	return parsedString;
};

export default useParsedMessage;
