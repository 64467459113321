import React, { useMemo } from "react";
import { extension } from "mime-db";

const LightboxThumbnail = ({ item, className, mms }) => {
	const data = useMemo(() => item, [item]);
	const url = useMemo(
		() => (item.file ? window.URL.createObjectURL(item.file) : item.audio_url),
		[item]
	);
	const thumbnail = useMemo(
		() =>
			item.thumbnail ? item.thumbnail : item.audio_url ? item.audio_url : url,
		[item, url]
	);
	const type = useMemo(() => {
		if (data.file) return data.file.type;
		else if (data.file_type) {
			return extension[data.file_type]?.join(",") || data.file_type;
		} else if (data.audio_url) {
			const extensionMatch = url.match(/\.([^.?]+)(?:\?|$)/);
			if (extensionMatch) {
				const ext = extensionMatch[1];
				return extension[ext]?.join(",") || ext;
			}
		} else return data.type;
	}, [data]);

	const thumbType = useMemo(() => {
		const _url = item.thumbnail
			? item.thumbnail
			: item.audio_url
			? item.audio_url
			: url;
		const ext = extension[_url.split(".").pop()]?.join(",");

		return ext?.includes("image")
			? "image"
			: ext?.includes("video")
			? "video"
			: ext?.includes("mms")
			? "mms"
			: "file";
	}, [item, url, type]);

	const getClassName = (mimetype) => {
		if (mimetype.indexOf("pdf") !== -1) return "pdf";
		else if (mimetype.indexOf("zip") !== -1) return "zip";
		else if (mimetype.indexOf("wordprocessingml") !== -1) return "word";
		else if (
			mimetype.indexOf("msword") !== -1 ||
			mimetype.indexOf("x-cfb") !== -1
		)
			return "doc";
		else if (mimetype.indexOf("text") !== -1) {
			if (mimetype.indexOf("plain") !== -1) return "txt";
			else if (mimetype.indexOf("css") !== -1) return "css";
			else if (mimetype.indexOf("x-php") !== -1) return "php";
			else return "text";
		} else if (mimetype.indexOf("audio") !== -1) {
			if (mimetype.indexOf("mpeg")) return "mp3";
			else return "music";
		} else if (mimetype.indexOf("video") !== -1) return "video";
		else if (mimetype.indexOf("presentation") !== -1) return "powerpoint";
		else if (mimetype.indexOf("powerpoint") !== -1) return "ppt";
		else if (mimetype.indexOf("sheet") !== -1) return "excel";
		else if (mimetype.indexOf("excel") !== -1) return "xls";
		else if (mimetype.indexOf("photoshop") !== -1) return "psd";
		else if (mimetype.indexOf("sql") !== -1) return "sql";
		else if (mimetype.indexOf("font") !== -1) {
			if (mimetype.indexOf("ttf") !== -1) return "ttf";
			else return "font-file";
		} else if (mimetype.indexOf("android.package-archive") !== -1) return "apk";
		else if (mimetype.indexOf("x-iso9660-image") !== -1) return "iso";
		else if (mimetype.indexOf("illustrator") !== -1) return "adobe-illustrator";
		else return "unsuported";
	};

	return (
		<>
			{type?.indexOf("image") !== -1 ? (
				<img
					src={url}
					className={`${
						!className?.includes("h-") && "h-full"
					} w-full object-cover pointer-events-none ${className}`}
				/>
			) : type?.indexOf("video") !== -1 ? (
				<>
					{thumbType === "image" ? (
						<img
							src={thumbnail}
							className={`${
								!className?.includes("h-") && "h-full"
							} w-full object-cover pointer-events-none ${className}`}
						/>
					) : (
						<video
							src={thumbnail}
							className={`${
								!className?.includes("h-") && "h-full"
							} w-full object-cover pointer-events-none ${className}`}
							onClick={(e) => e.stopPropagation()}
						/>
					)}
				</>
			) : type?.indexOf("mms") !== -1 ? (
				<>
					{thumbType === "mms" ? (
						<img
							src={thumbnail}
							className={`${
								!className?.includes("h-") && "h-full"
							} w-full object-cover pointer-events-none ${className}`}
						/>
					) : (
						<div
							className={`mms-content ${
								!className?.includes("h-") && "h-full"
							} w-full object-cover pointer-events-none ${className}`}>
							{/* Add your custom MMS rendering logic here */}
							{/* <p>MMS</p>  */}
							<img
								src={mms}
								className={`${
									!className?.includes("h-") && "h-full"
								} w-full object-cover pointer-events-none ${className}`}
							/>
						</div>
					)}
				</>
			) : type ? (
				<i
					className={`kt-ft ${getClassName(
						type
					)} mx-[8px] pointer-events-none ${className}`}
				/>
			) : null}
		</>
	);
};

export default LightboxThumbnail;
