import Dexie from "dexie";
import { DB_NAME } from "../constants";

export const db = new Dexie(DB_NAME);
db.version(1).stores({
	chats: "&chat_id,type,unix_time",
	messages: "&id,message_id,chat_id,type,unix_time",
	members: "id,chat_id,user_id",
	users: "id",
});
db.version(2).stores({
	chats: "&chat_id,type,unix_time",
	messages: "&id,message_id,chat_id,type,tag_id,unix_time",
	members: "id,chat_id,user_id",
	users: "id",
});
db.version(3).stores({
	chats: "&chat_id,type,unix_time",
	messages: "&id,message_id,identifier,chat_id,type,tag_id,unix_time",
	members: "id,chat_id,user_id",
	users: "id",
});
db.version(4).stores({
	chats: "&chat_id,type,unix_time",
	messages:
		"&id,message_id,identifier,chat_id,type,[chat_id+type],tag_id,unix_time",
	members: "id,chat_id,user_id",
	users: "id",
});
db.version(5).stores({
	chats: "&chat_id,type,unix_time",
	messages:
		"&id,message_id,identifier,chat_id,type,[chat_id+type],tag_id,unix_time",
	members: "id,chat_id,user_id",
	users: "id, name",
});

db.version(6).stores({
	chats: "&chat_id,type,unix_time",
	messages:
		"&id,message_id,identifier,chat_id,type,[chat_id+type],tag_id,unix_time",
	members: "[chat_id+id],chat_id,id",
	users: "id, name",
});
db.version(7).stores({
	chats: "&chat_id,type,unix_time",
	messages:
		"&id,message_id,identifier,chat_id,type,[chat_id+type],is_read,tag_id,unix_time",
	members: "[chat_id+id],chat_id,id",
	users: "id, name",
});
db.version(8).stores({
	chats: "&chat_id,type,unix_time",
	messages:
		"&id,message_id,identifier,chat_id,type,[chat_id+type],is_read,sender_id,tag_id,unix_time",
	members: "[chat_id+id],chat_id,id",
	users: "id, name",
	reactions: "&message_id",
});
db.version(9).stores({
	chats: "&chat_id,type,unix_time",
	messages:
		"&id,message_id,identifier,chat_id,type,[chat_id+type],is_read,sender_id,tag_id,unix_time",
	translatedMessages:
		"&id,message_id,identifier,chat_id,type,[chat_id+type],is_read,sender_id,tag_id,unix_time",
	members: "[chat_id+id],chat_id,id",
	users: "id, name",
	reactions: "&message_id",
});
db.version(10).stores({
	chats: "&chat_id,type,unix_time",
	messages:
		"&id,message_id,identifier,chat_id,type,[chat_id+type],is_read,sender_id,tag_id,unix_time",
	translatedMessages:
		"&id,message_id,identifier,chat_id,type,[chat_id+type],is_read,sender_id,tag_id,unix_time",
	members: "[chat_id+id],chat_id,id",
	users: "id, name",
	reactions: "&message_id",
	metaDatas: "url",
});
db.version(11).stores({
	chats: "&chat_id,type,unix_time",
	messages:
		"&id,message_id,identifier,chat_id,type,[chat_id+type],is_read,sender_id,tag_id,unix_time",
	translatedMessages:
		"&id,message_id,identifier,chat_id,type,[chat_id+type],is_read,sender_id,tag_id,unix_time",
	members: "[chat_id+id],chat_id,id",
	users: "id, name",
	reactions: "&message_id",
	metaDatas: "url",
	messageInfos: "&message_id",
});
db.version(12).stores({
	chats: "&chat_id,type,unix_time",
	messages:
		"&id,message_id,identifier,chat_id,type,[chat_id+type],is_read,sender_id,tag_id,unix_time",
	translatedMessages:
		"&id,message_id,identifier,chat_id,type,[chat_id+type],is_read,sender_id,tag_id,unix_time",
	members: "[chat_id+id],chat_id,id",
	users: "id, name",
	reactions: "&message_id",
	metaDatas: "url",
	//messageInfos: "&message_id",
	messageStatus: "&id,[message_id+user_id],message_id",
});
db.version(13).stores({
	chats: "&chat_id,type,unix_time",
	messages:
		"&id,message_id,identifier,chat_id,type,[chat_id+type],is_read,sender_id,tag_id,unix_time",
	translatedMessages:
		"&id,message_id,identifier,chat_id,type,[chat_id+type],is_read,sender_id,tag_id,unix_time",
	members: "[chat_id+id],chat_id,id",
	users: "id, name",
	reactions: "&message_id",
	metaDatas: "url",
	//messageInfos: "&message_id",
	messageStatus: "[message_id+user_id],message_id,chat_id",
});
db.version(14).stores({
	chats: "&chat_id,type,unix_time",
	messages:
		"&id,message_id,identifier,chat_id,type,[chat_id+type],is_read,sender_id,tag_id,unix_time",
	translatedMessages:
		"&id,message_id,identifier,chat_id,type,[chat_id+type],is_read,sender_id,tag_id,unix_time",
	members: "[chat_id+id],chat_id,id",
	users: "id, name",
	reactions: "&message_id",
	metaDatas: "url",
	//messageInfos: "&message_id",
	messageStatus: "[message_id+user_id],message_id,chat_id",
	drafts: "&chat_id",
});
db.version(15).stores({
	chats: "&chat_id,type,unix_time",
	messages:
		"&id,message_id,identifier,chat_id,type,[chat_id+type],is_read,sender_id,tag_id,unix_time",
	translatedMessages:
		"&id,message_id,identifier,chat_id,type,[chat_id+type],is_read,sender_id,tag_id,unix_time",
	members: "[chat_id+id],chat_id,id",
	users: "id,name,chat_id,contact_id,request_id",
	reactions: "&message_id",
	metaDatas: "url",
	//messageInfos: "&message_id",
	messageStatus: "[message_id+user_id],message_id,chat_id",
	drafts: "&chat_id",
});
db.version(16).stores({
	chats: "&chat_id,type,unix_time",
	messages:
		"&id,message_id,identifier,chat_id,group_id,type,[chat_id+type],is_read,sender_id,tag_id,unix_time",
	translatedMessages:
		"&id,message_id,identifier,chat_id,group_id,type,[chat_id+type],is_read,sender_id,tag_id,unix_time",
	members: "[chat_id+id],chat_id,id",
	users: "id,name,chat_id,contact_id,request_id",
	reactions: "&message_id",
	metaDatas: "url",
	//messageInfos: "&message_id",
	messageStatus: "[message_id+user_id],message_id,chat_id",
	drafts: "&chat_id",
});
db.version(17).stores({
	chats: "&chat_id,type,unix_time",
	messages:
		"&id,message_id,identifier,chat_id,group_id,type,[chat_id+type],[chat_id+sender_id+is_read],is_read,sender_id,tag_id,unix_time",
	translatedMessages:
		"&id,message_id,identifier,chat_id,group_id,type,[chat_id+type],[chat_id+sender_id+is_read],is_read,sender_id,tag_id,unix_time",
	members: "[chat_id+id],chat_id,id",
	users: "id,name,chat_id,contact_id,request_id",
	reactions: "&message_id",
	metaDatas: "url",
	//messageInfos: "&message_id",
	messageStatus: "[message_id+user_id],message_id,chat_id",
	drafts: "&chat_id",
});
db.version(18).stores({
	chats: "&chat_id,type,unix_time",
	messages:
		"&id,message_id,identifier,chat_id,group_id,type,[chat_id+type],[chat_id+sender_id+is_read],is_read,sender_id,tag_id,unix_time",
	translatedMessages:
		"&id,message_id,identifier,chat_id,group_id,type,[chat_id+type],[chat_id+sender_id+is_read],is_read,sender_id,tag_id,unix_time",
	members: "[chat_id+id],chat_id,id",
	users: "id,name,chat_id,contact_id,request_id",
	reactions: "&message_id",
	metaDatas: "url",
	messageStatus: "[message_id+user_id],message_id,chat_id",
	drafts: "&chat_id",
	friendRequests: "request_id, status",
});
db.version(19).stores({
	chats: "&chat_id,type,unix_time",
	messages:
		"&id,message_id,identifier,chat_id,group_id,type,[chat_id+type],[chat_id+sender_id+is_read],is_read,sender_id,tag_id,unix_time",
	translatedMessages:
		"&id,message_id,identifier,chat_id,group_id,type,[chat_id+type],[chat_id+sender_id+is_read],is_read,sender_id,tag_id,unix_time",
	members: "[chat_id+id],chat_id,id",
	users: "id,name,chat_id,contact_id,request_id",
	reactions: "&message_id",
	metaDatas: "url",
	messageStatus: "[message_id+user_id],message_id,chat_id",
	drafts: "&chat_id",
	friendRequests: "&request_id, user_id, is_read",
	mentions: "++id, chat_id, message_id, type, is_read",
});