import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { post } from "../../../axios/axiosClient";
import { db } from "../../../js/db";
import { getValuesByPartialKey, isValidUrl } from "../../../utils/functions";
import urlJoin from "url-join";
import { encrypt } from "../../../utils/crypto";

const initialState = {
	metaResponse: {},
	isLoading: false,
	error: false,
};

export const metaSlice = createSlice({
	name: "location",
	initialState,
	reducers: {
		saveMetaResponse: (state, action) => {
			state.metaResponse = action.payload;
		},
		resetMetaResponse: (state) => {
			state.metaResponse = {};
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchMeta.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(fetchMeta.fulfilled, (state, action) => {
				state.isLoading = false;
				if (!action.payload) return;
				state.metaResponse = action.payload;
				const title = getValuesByPartialKey(action.payload?.data, "title");
				const description = getValuesByPartialKey(
					action.payload?.data,
					"description"
				);
				const image = getValuesByPartialKey(action.payload?.data, "image")
					? getValuesByPartialKey(action.payload?.data, "image")[0] instanceof
					  Array
						? getValuesByPartialKey(action.payload?.data, "image")[0][0].url
						: getValuesByPartialKey(action.payload?.data, "image")[0]
					: action.payload?.data?.favicon || null;
				const imgType = image
					? getValuesByPartialKey(action.payload?.data, "image")
						? "banner"
						: "favicon"
					: null;

				const metaObj = {
					...(title && {
						title: title[0],
					}),
					...(description && {
						description: description[0],
					}),
					...(image && {
						image: isValidUrl(image)
							? image
							: urlJoin(action.payload?.data?.requestUrl, image),
					}),
					...(imgType && { imgType: imgType }),
					...(title && { url: action.payload?.data?.requestUrl }),
				};

				Object.keys(metaObj).length > 0 && db.metaDatas.put(metaObj);
			})
			.addCase(fetchMeta.rejected, (state, action) => {
				state.isLoading = false;
				state.error = action.error.message;
			});
	},
});

const fetchMeta = createAsyncThunk("location/fetchMeta", async (values) => {
	if (!values) return;

	return post("/user/url_metadata", {
		type: "application/x-www-form-urlencoded",
		data: { encodedParams: await encrypt(JSON.stringify(values)) },
	});
});

export const { saveMetaResponse, resetMetaResponse } = metaSlice.actions;
export { fetchMeta };

export default metaSlice.reducer;
