import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { post } from "../../../axios/axiosClient";
import { db } from "../../../js/db";
import { encrypt } from "../../../utils/crypto";

const initialState = {
	messageInfoResponse: {},
	isLoading: false,
	error: false,
};

export const getMessageInfoSlice = createSlice({
	name: "getMessageInfo",
	initialState,
	reducers: {
		saveMessageInfoResponse: (state, action) => {
			state.messageInfoResponse = action.payload;
		},
		resetMessageInfoResponse: (state) => {
			state.messageInfoResponse = {};
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getMessageInfoService.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(getMessageInfoService.fulfilled, (state, action) => {
				state.isLoading = false;
				if (!action.payload) return;
				state.messageInfoResponse = action.payload;

                try {
					 action.payload?.data && 
					db.messageStatus
					.bulkPut(action.payload?.data?.users)
					.then(async function (lastKey) {
					})
					.catch(async function (err) {
					});
					
                } catch (error) {
                }
			})
			.addCase(getMessageInfoService.rejected, (state, action) => {
				state.isLoading = false;
				state.error = action.error.message;
			});
	},
});

const getMessageInfoService = createAsyncThunk(
	"chat/getMessageInfo",
	async (values) => {
		if (!values) return;

		return post("/chat/messageInfo", {
			type: "application/json",
			data: { encodedParams: await encrypt(JSON.stringify(values)) },
		});
	}
);

export const { saveMessageInfoResponse, resetMessageInfoResponse } =
	getMessageInfoSlice.actions;
export { getMessageInfoService };

export default getMessageInfoSlice.reducer;
