import {
	Link,
	List,
	ListItem,
	Messages,
	NavLeft,
	NavTitle,
	Navbar,
	Page,
	PageContent,
	Preloader,
} from "framework7-react";
import React, { useEffect, useMemo, useState } from "react";
import MaterialIcon from "../../../../components/misc/materialIcon";
import { useTranslation } from "react-i18next";
import RenderMessage from "../../../../components/messages/renderMessage";
import { db } from "../../../../js/db";
import { useLiveQuery } from "dexie-react-hooks";
import { useSelector } from "react-redux";
import LazyLoaderWraper from "../../../../components/misc/lazyloaderWraper";
import user_placeholder from "../../../../assets/images/placeholders/user.png";
import "./style.css";
import moment from "moment";
import useLocalStorageState from "use-local-storage-state";

const MessageInfoPanel = ({
	reactionsOpen,
	members,
	type,
	displayMenu,
	opened,
}) => {
	const { t } = useTranslation();
	const [messageContext] = useLocalStorageState("messageContext");
	const [messageShouldRender, setMessageShouldRender] = useState(false);

	const translatedMessage = useLiveQuery(
		() =>
			!Array.isArray(messageContext) &&
			db.translatedMessages.where({ id: messageContext?.id }).first(),
		[messageContext],
		null
	);

	const message = useMemo(() => {
		return translatedMessage || messageContext;
	}, [translatedMessage, messageContext]);

	useEffect(() => {
		if (!opened) return;
		setTimeout(() => setMessageShouldRender(true), 300);
	}, [opened]);

	const { isLoading } = useSelector((state) => state.getMessageInfoSlice);

	const messageInfo = useLiveQuery(
		() =>
			!Array.isArray(messageContext)
				? db.messageStatus.where({ message_id: messageContext?.id }).toArray()
				: db.messageStatus
						.where({ message_id: messageContext[0]?.id })
						.toArray(),
		[messageContext],
		[]
	);

	const deliveredToInfo = useMemo(
		() =>
			messageInfo && messageInfo.length > 0
				? messageInfo.filter((item) => item.status === "delivered")
				: [],
		[messageInfo]
	);
	const seenByInfo = useMemo(
		() =>
			messageInfo && messageInfo.length > 0
				? messageInfo.filter((item) => item.status === "seen")
				: [],
		[messageInfo]
	);
	const remainingDelivered =
		messageInfo && messageInfo.length > 0
			? members.length - messageInfo.length
			: members.length;
	const remainingSeen = seenByInfo
		? members.length - seenByInfo.length
		: members.length;
	return (
		<Page id="messageInfoPage" pageContent={false}>
			<Navbar>
				<NavLeft>
					<Link panelClose>
						<MaterialIcon size={48} icon="close" />
					</Link>
				</NavLeft>
				<NavTitle className="text-[--f7-heading-color] font-bold">
					{t("Message Info")}
				</NavTitle>
			</Navbar>

			<PageContent className="flex flex-col gap-[40px]">
				<Messages className="min-h-[auto]">
					{message && messageShouldRender && (
						<RenderMessage
							message={message}
							reactionsOpen={reactionsOpen}
							members={members}
							type={type}
							displayMenu={displayMenu}
							infoOpened={opened}
							fullWidth
						/>
					)}
				</Messages>

				{messageInfo ? (
					<>
						<div className="flex flex-col gap-[9px] px-[20px]">
							<h1 className="font-extrabold text-sm text-secondary uppercase">
								{t("Read By")}
							</h1>
							{seenByInfo && seenByInfo.length > 0 && (
								<List className="m-0 p-0">
									{seenByInfo.map((seenBy, index) => (
										<ListItem
											className="msg-info-item"
											key={"seeninfo" + seenBy.id + index}
											header={seenBy.nickname || seenBy.firstname}
											footer={moment(seenBy.seen_timestamp).format(
												"DD MMMM, h:mma"
											)}>
											<LazyLoaderWraper
												slot="media"
												id={seenBy.id}
												src={
													seenBy.hide_profile_picture
														? user_placeholder
														: seenBy.profile_image
												}
												placeholder={user_placeholder}
												height={55}
												width={55}
												alt=""
												className="rounded-full max-h-[55px] object-cover"
											/>
										</ListItem>
									))}
								</List>
							)}
							{type && type == "group" && remainingSeen > 0 && (
								<h1 className="font-semibold text-sm text-secondary">
									{remainingSeen} {t("Remaining")}
								</h1>
							)}
						</div>

						{deliveredToInfo && deliveredToInfo.length > 0 && (
							<div className="flex flex-col gap-[9px] px-[20px]">
								<h1 className="font-extrabold text-sm text-secondary uppercase">
									{t("Delivered To")}
								</h1>
								<List className="m-0 p-0">
									{deliveredToInfo.map((delivered, index) => (
										<ListItem
											className="msg-info-item"
											key={"deliverinfo" + delivered.id + index}
											header={delivered.nickname || delivered.firstname}
											footer={moment(delivered.delivered_timestamp).format(
												"DD MMMM, h:mma"
											)}>
											<LazyLoaderWraper
												slot="media"
												id={delivered.id}
												src={
													delivered.hide_profile_picture
														? user_placeholder
														: delivered.profile_image
												}
												placeholder={user_placeholder}
												height={55}
												width={55}
												alt=""
												className="rounded-full max-h-[55px] object-cover"
											/>
										</ListItem>
									))}
								</List>
								{type && type == "group" && remainingDelivered > 0 && (
									<h1 className="font-semibold text-sm text-secondary">
										{remainingDelivered} {t("Remaining")}{" "}
									</h1>
								)}
							</div>
						)}
					</>
				) : isLoading ? (
					<div className="h-full w-full flex justify-center items-center">
						<Preloader color="blue" />
					</div>
				) : (
					<div className="h-full w-full flex justify-center items-center">
						{t("No info found for this message")}
					</div>
				)}
			</PageContent>
		</Page>
	);
};

export default MessageInfoPanel;
