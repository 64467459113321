import React, {useContext, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	Page,
	Button,
	List,
	ListInput,
	ListItem,
	f7,
	Preloader,
	PageContent,
	} from "framework7-react";
import { useUpdateEffect } from "react-use";
import {AUTHTOKEN_COOKIE, STATUS_CODE } from "../../constants";
import AuthNabvar from "../../components/navs/authNavbar";
import {  Profile } from "iconsax-react";
import imgcamera from "../../assets/images/icons/imgcamera.svg";
import  Group2 from "../../assets/images/icons/Group2.svg";
import  Group3 from "../../assets/images/icons/Group3.svg";
import  Group4 from "../../assets/images/icons/Group4.svg";
import  Group5 from "../../assets/images/icons/Group5.svg";
import  Group6 from "../../assets/images/icons/Group6.svg";
import  Group7 from "../../assets/images/icons/Group7.svg";
import  Group8 from "../../assets/images/icons/Group8.svg";
import  Group9 from "../../assets/images/icons/Group9.svg";
import  Group10 from "../../assets/images/icons/Group10.svg";
import  Group11 from "../../assets/images/icons/Group11.svg";
import  Group12 from "../../assets/images/icons/Group12.svg";
import  Group13 from "../../assets/images/icons/Group13.svg";
import  Group14 from "../../assets/images/icons/Group14.svg";
import  Group15 from "../../assets/images/icons/Group15.svg";
import  Group16 from "../../assets/images/icons/Group16.svg";
import { SocketContext } from "../../socket";
import {createOpenDialog,getFileType} from "../../utils/functions";
import { setCookie } from "../../utils/functions";
import "./style.css";
import {ProfileUpdateService} from "../../redux/features/userSlice/profileUpdate"
import { uploadFileService } from "../../redux/features/chatSlice/uploadFile";
import { useTranslation } from "react-i18next";



const SignUpProfile = ({ f7router }) => {
	const [avatars, setAvatars] = useState([ Group2, Group3, Group4, Group5, Group6, Group7, Group8, Group9, Group10, Group11, Group12, Group13, Group14, Group15 ,Group16]);
	const [profileImage, setProfileImage] = useState({});
	const { t, i18n } = useTranslation();
	const deviceInfo = f7.device;
	const dispatch = useDispatch();
	const {  loginResponse } = useSelector(
		(state) => state.loginSlice
	);

	const { userProfileUpdateResponse, error } = useSelector(
    (state) => state.profileUpdateSlice
  );
	const { uploadFileResponse } = useSelector((state) => state.uploadFileSlice);
	const chat_id = loginResponse?.data.chat_id
	const [displayName, setDisplayName] = useState(loginResponse?.extra?.display_name);
	const [isLoading, setIsLoading] = useState(false);
	const [image, setImage] = useState(null);
	const [imgUrl, setImgUrl] = useState();
	const [selectedImage, setSelectedImage] = useState(profileImage.url);
	const socket = useContext(SocketContext);

	useEffect(() => {
		chooseAvatar(avatars[0]);
	}, []);
	
	useEffect(() => {
		if (loginResponse.code === STATUS_CODE.SUCCESS) {
		  setCookie(AUTHTOKEN_COOKIE, loginResponse.data.token);
		} else {
		  //show error
		  f7.toast.create({ text: loginResponse.message }).open();
		}
	  }, [loginResponse]);

	useUpdateEffect(() => {
		if (userProfileUpdateResponse?.code === STATUS_CODE.SUCCESS) {
			f7.toast
				.create({
					text:  userProfileUpdateResponse?.message,
				})
				.open();
				f7router.navigate(`${window.innerWidth < 775 ? "/chats/" : "/chats/null/single"}`);
		} else {
			f7.toast
				.create({
					text:  userProfileUpdateResponse?.message,
				})
				.open();
		}
	}, [userProfileUpdateResponse, error]);

	const chooseAvatar = async (avatar_image) => {
		const response = await fetch(avatar_image);
		var file_name = avatar_image.split('/').pop();
		const blob = await response.blob();
		const file = new File([blob], file_name, {
			type: blob.type,
		});

		setProfileImage({ url: avatar_image, file });
		setSelectedImage(avatar_image);
		// uploadImage(file)
	};

	useUpdateEffect(() => {
		if (uploadFileResponse?.code === STATUS_CODE.SUCCESS) {
			const uploadedFile = uploadFileResponse.data[0].file_url;
			setImgUrl(uploadedFile);
		}
	}, [uploadFileResponse]);

	const attachFileHandler = (type = "image") => {
		const fileInput = createOpenDialog(type, "file", false);

		fileInput.onchange = (_) => {
			var file = fileInput.files[0];
			var url = URL.createObjectURL(file);
			setProfileImage({ url, file });
			uploadImage(file)
			setSelectedImage(url)
			fileInput.remove();
		};

		fileInput.click();
	};

	const uploadImage = async (file) => {
		try {
			const formData = new FormData();
			formData.append("type", getFileType(file));
			formData.append("file", file);
			const response = await dispatch(uploadFileService(formData));
		} catch (error) {
			console.error("Error uploading image:", error);
		}
	};

	const updateProfile = () => {
		dispatch(
			ProfileUpdateService({
				first_name: displayName,
				profile_image: imgUrl,
				token: loginResponse.data.token
			}))
	}

	return (
		<Page name="SignUpProfile" pageContent={false}>
			<AuthNabvar />
			<PageContent className="flex mt-[40px] w-full px-[40px] md:px-0 justify-center flex-wrap gap-[100px] main-bg-bubble">
				<div className="w-full md:w-[400px]">
					<div className="pl-[30px]">
						<p className="text-[26px] font-boldtext-[--f7-heading-color-color] font-bold font-[--f7-font-family]">
						 {t("Complete your profile")}
						</p>
					</div>
					<List className="list-none" form>
					<ListInput
							type="text"
							placeholder="Enter your Name"
							className="input-field-normal list-none w-full mt-4"
							value={displayName}
							onInput={(e) => {
								setDisplayName(e.target.value);
							}}
						
							>
							<div slot="media">
								<Profile
									color="#5d6980"
									variant="Bold"
									size={22}
								/>
							</div>
						</ListInput>
					</List>
					<div className="flex justify-center">{t("Select and upload a photo")}</div>
					<div className="flex justify-center items-center mt-[20px] mb-[20px]">
					<div className="circular_image" >
                       <img src= { selectedImage }
						alt= {'selected profile avater ${selectedAvatar +1} '}
						/>
					   <div className="second-imge">
						<div className="flex justify-center items-center"><img src={imgcamera} onClick={() => { attachFileHandler();}}/></div>
					
					</div>
					  
                    </div> 
					
					</div>
					<div className="flex justify-center items-center">{t("Or choose from the following avatars below")}</div>
					<div className="w-full md:w-[400px]">
					<div className="flex justify-around mt-[20px] mb-[20px]" style={{flexWrap: 'wrap' }}>
						{avatars.map((avatar, index) => (
							<img
								key={index}
								src={avatar}
								alt ={'profile avater ${index +1}'}
								style={{
									width: '48px',
									height:'48px',
									BordeerRadius: '50%',
									margin: '10px',
									cursor: 'pointer',
								}}
								onClick={() => chooseAvatar(avatar)}
							/>
		                ))}
					</div>
						
				   </div>
					<ListItem className="mt-5 button-container mr-[-15px]  list-none">
						<Button
							fill
							onClick={ updateProfile }
							className="w-full"
							disabled={
								!displayName.length || isLoading
							}
							>
							{isLoading ? (
								<Preloader color="white" />
							) : (
								t("Next")
							)}
						</Button>
					</ListItem>
				</div>
				
			</PageContent>

		</Page>
	);
};

export default SignUpProfile;