import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { post } from "../../../axios/axiosClient";
import { encrypt } from "../../../utils/crypto";

const initialState = {
	LoggedDeviceResponse: {},
	isLoading: false,
	error: null,
};

const LoggedDeviceSlice = createSlice({
	name: "loggedDevice",
	initialState,
	reducers: {
		saveLoggedDeviceResponse: (state, action) => {
			state.LoggedDeviceResponse = action.payload;
		},
		resetLoggedDeviceResponse: (state) => {
			state.LoggedDeviceResponse = {};
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(LoggedDeviceService.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(LoggedDeviceService.fulfilled, (state, action) => {
				state.isLoading = false;
				if (!action.payload) return;
				state.LoggedDeviceResponse = action.payload;
			})
			.addCase(LoggedDeviceService.rejected, (state, action) => {
				state.isLoading = false;
				state.error = action.error.message;
			})
			.addCase(DeviceLogoutService.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(DeviceLogoutService.fulfilled, (state, action) => {
				state.isLoading = false;
				if (!action.payload) return;
				
				if (state.LoggedDeviceResponse.data) {
					state.LoggedDeviceResponse.data = state.LoggedDeviceResponse.data.filter(
						session => session.id !== action.payload.extra.sessionId
					);
				}
			})
			.addCase(DeviceLogoutService.rejected, (state, action) => {
				state.isLoading = false;
				state.error = action.error.message;
			});
	},
});

export const LoggedDeviceService = createAsyncThunk(
	"user/sessions",
	async (values) => {
		if (!values) return;

		return post("user/sessions", {
			type: "application/x-www-form-urlencoded",
			data: { encodedParams: await encrypt(JSON.stringify(values)) },
		});
	}
);
export const DeviceLogoutService = createAsyncThunk(
	"user/deleteSession",
	async (values) => {
		if (!values) return;

		return post("user/deleteSession", {
			type: "application/x-www-form-urlencoded",
			data: { encodedParams: await encrypt(JSON.stringify(values)) },
		});
	}
);
export const { saveLoggedDeviceResponse, resetLoggedDeviceResponse } =
	LoggedDeviceSlice.actions;
export default LoggedDeviceSlice.reducer;
